$tile-red: #ad2b1a;
$tile-blue: #152fc5;
$tile-cyan: #2cb8ac;
$tile-yellow: #f5aa0a;

@mixin tile($color) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 5%;
  background-color: $color;
  border-color: darken($color, 20%);
  border-radius: 15%;
  box-shadow: 0 0.25rem 0 darken($color, 20%);
  transform: translateY(-0.25rem);
}

.cost {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  height: 25%;
  font-weight: bold;
  font-size: 1.5rem;
  font-family: sans-serif;
  background: #fff;
  border-radius: 50%;
}

.blue {
  @include tile($tile-blue);
}

.red {
  @include tile($tile-red);
}

.cyan {
  @include tile($tile-cyan);
}

.yellow {
  @include tile($tile-yellow);
}
