$margin: 15px;

.outer {
  width: 120px;
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    position: relative;
    width: 100px;
    height: 100px;
    margin: $margin;
    cursor: pointer;
    transition: transform 100ms ease-in-out;
  }

  > li:hover:not(.selected):not(.disabled):not(.emptySlot):not(.separator) {
    z-index: 1;
    transform: scale(1.1);
  }

  > .selected {
    z-index: 1;
    border-radius: 15%;
    box-shadow: 0 0 10px rgba(255, 255, 255, 1);
  }

  > .disabled {
    width: 80px;
    height: 80px;
    margin: $margin;
    cursor: not-allowed;
  }

  > .emptySlot {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
  }

  > .emptySlot::after {
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 15%;
    content: '';
  }

  > .separator {
    height: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: rgba(255, 255, 255, 0.5);
  }
}
