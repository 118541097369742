.grid {
  position: relative;
  transition: top 100ms ease-out, left 100ms ease-out;
}

.item {
  position: absolute;
  pointer-events: none;

  &.animate {
    transition: top 100ms ease-out, left 100ms ease-out;
  }
}

.dropArea {
  width: 100%;
  height: 100%;
  border: 2px dashed #fff;
  border-radius: 15%;
  opacity: 0.2;
  pointer-events: all;
}

.ghost {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  pointer-events: none;
}

.highlight {
  width: 100%;
  height: 100%;
  border-radius: 15%;
  box-shadow: 0 0 10px rgba(255, 255, 255, 1);
  pointer-events: all;
}
