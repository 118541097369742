body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.root {
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}
