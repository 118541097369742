.screen {
  position: relative;
  display: grid;
  grid-template-areas:
    'left-sidebar header right-sidebar'
    'left-sidebar main right-sidebar'
    'left-sidebar footer right-sidebar';
  grid-template-rows: 150px auto 100px;
  grid-template-columns: 170px auto 150px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(66, 105, 75);
}

.playerGrid {
  position: relative;
  grid-area: main;
}

.scrollArea {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  grid-area: left-sidebar;
  overflow: auto;
}

.tileBar {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-area: left-sidebar;
  align-items: flex-start;
  overflow: auto;
}

.playerTrack {
  position: relative;
  display: flex;
  grid-area: header;
  align-items: center;
  justify-content: center;
}

.grid {
  position: absolute;
  top: 50%;
  left: 50%;
}

.footer {
  position: relative;
  display: flex;
  grid-area: footer;
  align-items: center;
  justify-content: center;
}

.confirmButton {
  margin: 10px;
  font-size: 3rem;
  border-radius: 10px;
}

.refillButton {
  margin: 10px;
  font-size: 3rem;
  border-radius: 10px;
}
