$tile-red: #ad2b1a;
$tile-blue: #152fc5;
$tile-cyan: #2cb8ac;
$tile-yellow: #f5aa0a;

.objective {
  position: relative;
  display: grid;
  grid-gap: 2%;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  width: 27%;
  height: 100%;
  padding: 2%;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

.completed {
  opacity: 0.56;

  &::after {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    background-color: #fff;
    transform: rotate(10deg);
    content: '';
  }

  &::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    background-color: #fff;
    transform: rotate(-10deg);
    content: '';
  }
}

@mixin objective-item() {
  box-sizing: border-box;
  border-style: solid;
  border-width: 1px;
  border-radius: 25%;
}

.blue {
  @include objective-item();
  background-color: $tile-blue;
  border-color: darken($tile-blue, 20%);
}

.red {
  @include objective-item();
  background-color: $tile-red;
  border-color: darken($tile-red, 20%);
}

.cyan {
  @include objective-item();
  background-color: $tile-cyan;
  border-color: darken($tile-cyan, 20%);
}

.yellow {
  @include objective-item();
  background-color: $tile-yellow;
  border-color: darken($tile-yellow, 20%);
}
