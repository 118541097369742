.playerList {
  margin: 0;
  padding: 0;
  list-style: none;

  > li {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 10px 0;
  }
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-weight: bold;
  font-family: sans-serif;
  background: #fff;
  border: 2px solid #000;
  border-radius: 50%;
  cursor: pointer;
}

.selected {
  box-shadow: 0 0 10px #fff;
}

.score {
  color: #fff;
  font-weight: bold;
  font-family: sans-serif;
}
