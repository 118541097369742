.track {
  display: flex;
  align-items: center;
  justify-content: center;
}

.token {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  font-weight: bold;
  font-family: sans-serif;
  background: #fff;
  border: 2px solid #000;
  border-radius: 50%;
}

.preview {
  position: relative;
  z-index: 1;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  border: 3px dashed #000;
  border-radius: 50%;
  opacity: 0.5;
}

.tile {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;
  width: 50px;
  height: 100px;
  padding-bottom: 10px;
}

.tile::after {
  position: absolute;
  right: 4px;
  bottom: 0;
  left: 4px;
  display: block;
  height: 8px;
  background: rgba(255, 255, 255, 0.5);
  content: '';
}
